@use '~@sbt-web/ui/common/css/variables' as v;

.label {
  color: var(--sbt-primary-text-color);
  font-size: v.$secondary_font_size;
  line-height: 20px;
  margin: 0 0 6px;
  display: block;
  font-weight: 600;
}

.form {
  height: 100%;
}

.compact-form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: var(--sbt-spacing-md);
  column-gap: var(--sbt-spacing-sm);
}

.linear-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: var(--sbt-spacing-md);
}

.cta {
  margin-top: auto;
}

.ctaMotorColor {
  --sbt-primary-color: #{v.$blueMotori};
  --sbt-primary-color-l1: #{v.$blueMotoriL1};
  --sbt-primary-color-d1: #{v.$blueMotoriD1};
}
